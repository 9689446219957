@charset "UTF-8";

#skrollr-body {min-height: 1px;}
.parallax.variant1 .bcg{position:relative;min-height:200px;overflow:hidden;background-size:cover!important;}
.parallax.variant1 .hsContent{color:#fff;padding-top:$padding-large;padding-bottom:$padding-large;background:none;}
.parallax.variant1 i.fa{font-size:35px;}
.parallax.variant1 h1,
.parallax.variant1 h2,
.parallax.variant1 h3,
.parallax.variant1 h4,
.parallax.variant1 h5,
.parallax.variant1 h6{color:#fff;}
.parallax.variant1 .intro,
.parallax.variant1 .intro p{color:#fff;}
.parallax.variant1 .btn{text-align:center;display:inline-block;}
.parallax.variant1 .btn a{@include ghost-button-light;color:#fff;border:1px solid #fff;display:inline-block;}
.parallax.variant1 .hsContent {padding-left:$padding-normal;padding-right:$padding-normal;}
.fullWidth .parallax.variant1 .hsContent {padding-left:0;padding-right:0;}
.parallax.variant1 .bcg:before {position:absolute;top:0;left:0;width:100%;height:100%;content:" ";background:$parallaxBgColor;}