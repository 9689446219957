@import "variables";
@import "./versions/2.0.7/base/scss/meetingSubscriptionFlowVariant1";
@import "./versions/2.0.7/base/scss/meetingVariant1";
@import "./versions/2.0.7/base/scss/datepicker";
// Theme - only 1 entry possible!!
@import "./versions/2.0.7/#themes/neptunus";

// inject:defaultWidgets:scss
@import "./versions/2.0.7/defaultWidgets/accordionVariant1/scss/_accordionVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/bannerVariant1/scss/_bannerVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/cookieVariant1/scss/_cookieVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/cookieSettingsVariant1/scss/_cookieSettingsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/documentsVariant1/scss/_documentsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/documentDetailVariant1/scss/_documentDetailVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/facebookVariant2/scss/_facebookVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/footerVariant1/scss/_footerVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/formV3Variant1/scss/_formV3Variant1.scss";
@import "./versions/2.0.7/defaultWidgets/galleryVariant1/scss/_galleryVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/highlightVariant1/scss/_highlightVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pagesSmallVariant1/scss/_pagesSmallVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant1/scss/_pagesVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant2/scss/_pagesVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant3/scss/_pagesVariant3.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant4/scss/_pagesVariant4.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant5/scss/_pagesVariant5.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant6/scss/_pagesVariant6.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant7/scss/_pagesVariant7.scss";
@import "./versions/2.0.7/defaultWidgets/parallaxVariant1/scss/_parallaxVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/personsVariant1/scss/_personsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pollVariant1/scss/_pollVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/searchVariant1/scss/_searchVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/sidebarVariant1/scss/_sidebarVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/sliderVariant1/scss/_sliderVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/sliderVariant2/scss/_sliderVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/textVariant1/scss/_textVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/twitterVariant1/scss/_twitterVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/twitterVariant2/scss/_twitterVariant2.scss";
// endinject:defaultWidgets

// inject:specialWidgets:scss
@import "./versions/2.0.7/specialWidgets/headerVariant3/scss/_headerVariant3.scss";
// endinject:specialWidgets

// inject:kollaWidgets:scss
// endinject:kollaWidgets
