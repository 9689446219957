@charset "UTF-8";

// Required
footer{@include page-layout;}

// Variantstyling
.footer.variant1 .top{background:$footer-top-background;color:$footer-top-color;}
.footer.variant1 .top .intro,
.footer.variant1 .top .intro p,
.footer.variant1 .top table td {color:$footer-top-color;}
.footer.variant1 .top a{color:$footer-top-link-color;text-decoration:$footer-top-link-text-decoration;}
.footer.variant1 .top .btn a{color:$footer-top-link-color;border:$footer-top-button-border;}
.footer.variant1 .top h1,
.footer.variant1 .top h2,
.footer.variant1 .top h3,
.footer.variant1 .top h4,
.footer.variant1 .top h5,
.footer.variant1 .top h6{color:$footer-top-heading-color;font-weight:$footer-top-heading-font-weight;font-family:$footer-top-heading-font-family;}
.footer.variant1 .bottom{background:$footer-bottom-background;color:$footer-bottom-color;text-align:center;padding-top:$padding-normal;padding-bottom:$padding-normal;}
.footer.variant1 .bottom p{margin:0;font-size:$footer-bottom-font-size;font-weight:$footer-bottom-font-weight;}
.footer.variant1 .bottom a{color:$footer-bottom-link-color;text-decoration:$footer-bottom-link-text-decoration;font-size:$footer-bottom-link-font-size;font-weight:$footer-bottom-link-font-weight;}
.footer.variant1 .top .formbuilder_form_submit_button input{@include ghost-button-light;}
.footer.variant1 .top .error_strings{color:#fff;}