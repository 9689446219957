@charset "UTF-8";

.meetingInfo .veld .titel:only-child {display:none;}
.meetingInfo .titel{float:left;font-weight:bold;width:40%;clear:left;}
.meetingInfo .waarde {margin:0 0 0 40%;}
.meetingInfo .waarde span{display:block;}
.meetingInfo .waarde.toonDatum span,
.meetingInfo .waarde.toonDatum{display:inline;}
#bijeenkomstenProgramma table {width:100%;@include meetingProgramTable;}
table.voorzieningen{width:100%;}
table.voorzieningOverzicht .formbuilder_element{width:25px;}
.schrijfin_link a{@include button;margin-right:$margin-normal;}
.bijeenkomst_formulier{margin-top:$margin-large;}
.bijeenkomst_formulier #FormbuilderV2RenderControl{padding:$padding-normal;overflow:hidden;@include meetingSubscribeForm;}
table.onderdelenOverzicht td{display:none;}
table.onderdelenOverzicht td.checkbox{display:table-cell;}
table.onderdelenOverzicht td.formbuilder_element{width:25px;}
table.onderdelenOverzicht td.formbuilder_label{width:auto!important;}
table.onderdelenOverzicht td.formbuilder_label strong{font-weight:normal;}
.programma table{border:0;}
.programma table tr{background:transparent;}
.programma td{padding-left:0;padding-right:0;}
.programma td.datum{vertical-align:top;width:40%;}
.programma h3{margin-top:$margin-large;}
.invitation table .label {background:none;color:inherit;border:none;font-size:inherit;padding:0.5625rem 0;width:40%;text-align:left;display:table-cell;}

// responsive
@media only screen and (max-width: 40em) {
	.meetingInfo {
		.titel, .waarde {float:none;}
		.waarde {margin:0 0 15px 0;
			span.subveld {margin:0;}
		}
	}
	.programma .onderdelen {
		td {display:block; width:100% !important;
		&:first-child {font-weight:bold;}
		strong {font-weight:normal; font-style: italic;}
		}
	}
}
