@charset "UTF-8";

// @mixins
//
// We use this to create isosceles triangles
// $triangle-size - Used to set border-size. No default, set a px or em size.
// $triangle-color - Used to set border-color which makes up triangle. No default
// $triangle-direction - Used to determine which direction triangle points. Options: top, bottom, left, right
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if ($triangle-direction == top) {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if ($triangle-direction == bottom) {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if ($triangle-direction == left) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if ($triangle-direction == right) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

@mixin single-transition($property:all, $speed:300ms, $ease:ease-out) {
  transition: $property $speed $ease;
}

.pages.variant7 {
	.accordion {margin: 0 0 1.25rem 0;
		.accordion-navigation>a:after {@include css-triangle(5px, $secondary-color, top);float:right;margin-top:rem-calc(12);@include single-transition;transform: rotate(0deg);}
		.accordion-navigation.active>a:after {float:right;transform: rotate(180deg);margin-top:rem-calc(7);}
		.accordion-navigation {border-bottom:1px solid $border-color;}
		.content {overflow:hidden;}
	}
}