@charset "UTF-8";

// Search form
.search.variant1 {
	.submitveld input{@include button;}
	.fouten{color:$primary-color;margin-top:$margin-small;}
	@if ($block-search-button-position == 'right') {
		.veld {float:left; width: calc(100% - 115px); height: 53px;}
		.submitveld {float: right; min-width: 90px; height: 53px;
			input {width:100px; margin-top:0; height: 37px; line-height: 0;}
		}
	}
	span.label{
		padding:0;
		background:transparent;
		margin-bottom:10px;
	}
}

// Search result
.loaded .throbber{display:none;}
.searchResult.variant1 li{margin-bottom:$margin-normal;}