@charset "UTF-8";

.pages.variant2{}
.pages.variant2 article{padding-bottom:$padding-normal;border-bottom:1px solid $border-color;margin:0 0 $margin-normal 0!important;}
.pages.variant2 time {color:$date-color;}
.pages.variant2 p img {float:right;width:150px;margin:0 0 $margin-normal $margin-normal;}
.large-1 .pages.variant2,
.large-2 .pages.variant2,
.large-3 .pages.variant2,
.large-4 .pages.variant2,
.large-5 .pages.variant2,
.large-6 .pages.variant2,
.large-7 .pages.variant2
{
	img {display:none;}
}

@media only screen and (max-width:30em){
	.pages.variant2 img {display:none!important;} 
}
