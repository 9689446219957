// Global
	body{background:$body-background;color:$main-color;font-family:$main-font;font-size:$main-font-size;}
	table tr th, table tr td {color:$main-color;line-height:1.5em!important;}
	a{color:$link-color;}
	a:hover{color:$link-hover-color;text-decoration:$link-hover-text-decoration;}
	hr{color:$border-color;}
	table{width:100%;}
	table tr td{border-bottom:1px solid $border-color;}
	blockquote{border-left:3px solid $primary-color;}
	.intro p,
	.intro{font-family:$intro-font;font-size:$intro-font-size;color:$intro-color;}
	.intro a{color:$intro-link-color;}
	section{padding:$section-spacing;overflow:hidden;}
	.center {text-align:center;}
	// Afbeelding uitlijning
	a img,
	img {border:none;outline:none;}
	.afbeelding {text-align:center;font-size:90%;}
	.afbeelding.links {float:left;margin:0 25px 2px 0;}
	.afbeelding.midden {margin:0 auto;display:block;clear:left;}
	.afbeelding.rechts {float:right;margin:0 2px 0 25px;}
	.afbeelding .ondertitel {display:block;clear:left;}
      // Buttons
	.btn {display:block;overflow:hidden;clear:both;}
	.btn a{font-size:$main-font-size;@include button;float:left;}
	// Highlight bar
	.highlightRow{background:$highlight-background;border-top:1px solid $border-color;border-bottom:1px solid $border-color;padding:$padding-large 0;}
	// Custom
	.fullWidth{
		@if($fullwidth-boxed) {
			width:100%;margin-left:auto;margin-right:auto;max-width:$frame-width;clear:both;display:block;
		} @else {width:100%;margin-left:auto;margin-right:auto;max-width:initial;clear:both;display:block;}	
	}
	.fullWidth .row,
	.slider .row {background:transparent;}
	.fullWidth section{padding:0;}

// Main
	main .row{background:$frame-background;}
	.row{max-width:$frame-width;}
	// Header
	body.home header {margin-bottom:0;}

// Article
	// Detail
	article detail {background:$article-detail-background;padding:$padding-small;margin-bottom:$margin-normal;display:block;color:$article-detail-color;}
	article detail time:before {font-family:'FontAwesome';content:'\f073';margin-right:$margin-small;}
	article detail .author:before {font-family:'FontAwesome';content:'\f040';margin-right:$margin-small;}
	article detail .source:before {font-family:'FontAwesome';content:'\f08e';margin-right:$margin-small;}
	article detail time,
	article detail span {margin-right:$margin-normal;}

// Header
	.header.variant1 .bottomWrapper{border-bottom:1px solid $border-color;}

// Embedresponsively
.embed-container {position:relative;padding-bottom:56.25%;height:0;overflow:hidden;max-width:100%;}.embed-container iframe, .embed-container object, .embed-container embed {position:absolute;top:0;left:0;width:100%;height:100%;}
#twitter-widget-0 {width:100%!important;}

// Cookies
.cookiesBlocked{padding:15px;background:#f8f8f8;}
#cookieMsg{
	position:fixed;
	left:0;
	bottom:0;
	background:rgba(0,0,0,0.8);
	width:100%;
	color:#fff;
	text-align:center;
	z-index:20;
	padding:15px 0;
	p{
		margin:0;
	}
	a{
		color:#fff;
		text-decoration: underline;
	}
	.cookieLinks{
		margin-top:10px;
		a{
			display:inline-block;
			background:#fff;
			color:$main-color;
			text-decoration: none;
			padding: 2px 5px;
			margin: 0 5px;
		}
	}
}