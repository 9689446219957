@charset "UTF-8";

// Required

// Variantstyling
.highlightRow h1,
.highlightRow h2,
.highlightRow h3,
.highlightRow h4,
.highlightRow h5,
.highlightRow h6 {color:$highlight-heading-color;}
.highlightRow,
.highlightRow .intro,
.highlightRow .intro p {color:$highlight-color;}
.highlight.variant1 h3{overflow:hidden;}
.highlightRow .highlight.variant1 h1,
.highlightRow .highlight.variant1 h2,
.highlightRow .highlight.variant1 h3,
.highlightRow .highlight.variant1 h4,
.highlightRow .highlight.variant1 h5,
.highlightRow .highlight.variant1 h6 {color:$highlight-heading-color;}
.highlight.variant1 .icon i{background:$highlight-icon-background;border:$highlight-icon-border;width:35px;height:35px;text-align:center;border-radius:18px;float:left;margin-right:$margin-normal;line-height:1;}
.highlightRow .highlight.variant1 .icon i{color:$white;}
.highlight.variant1 .icon i{display:none;font-size:rem-calc(20);padding-top:rem-calc(5);}
.highlight.variant1 .icon i[class*='fa-'] {display:inline-block;}

.highlightRow .highlight.variant1 .icon i{color:$highlight-icon-color;}
.highlight.variant1 .highlightContent,
.highlight.variant1 .highlightContent img {margin-bottom:$margin-normal;}
.highlightRow .highlight.variant1 .highlightContent{color:$highlight-color;}
.highlightRow .highlight.variant1 a,
.highlightRow .highlight.variant1 .btn a{color:$highlight-link-color;}