.cookieSettings{
	.cookieLinks{
		a{
			display:inline-block;
			float:left;
			clear:left;
			color:#ccc;
			position: relative;
			padding-left: 20px;
			&::before{
				font-family: FontAwesome;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&.acceptCookies::before{
				content:"\f00c";
			}
			&.declineCookies::before{
				content:"\f00d";
			}
			&.active{
				&::before{
				}
				color:$primary-color;
			}
		}
	}
}