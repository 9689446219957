/*.ui-helper-hidden {
    display: none;
}
.ui-helper-hidden-accessible {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}*/
.ui-helper-reset {
    border: 0 none;
    font-size: 100%;
    line-height: 1.3;
    list-style: outside none none;
    margin: 0;
    outline: 0 none;
    padding: 0;
    text-decoration: none;
}
.ui-helper-clearfix::before, .ui-helper-clearfix::after {
    border-collapse: collapse;
    content: "";
    display: table;
}
.ui-helper-clearfix::after {
    clear: both;
}
.ui-helper-clearfix {
    min-height: 0;
}
.ui-helper-zfix {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.ui-front {
    z-index: 100;
}
.ui-state-disabled {
    cursor: default !important;
}
.ui-icon {
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    text-indent: -99999px;
}
.ui-widget-overlay {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}
.ui-datepicker {
    display: none;
    padding: 0.2em 0.2em 0;
    width: 17em;
}
.ui-datepicker .ui-datepicker-header {
    padding: 0.2em 0;
    position: relative;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    height: 1.8em;
    position: absolute;
    top: 2px;
    width: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
}
.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}
.ui-datepicker .ui-datepicker-next {
    right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
    right: 1px;
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: block;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
}
.ui-datepicker .ui-datepicker-title {
    line-height: 1.8em;
    margin: 0 2.3em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
    width: 45%;
    height: auto;
    padding: rem-calc(4);
    text-align: center;
    background-color: transparent;
    border: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    color: #FFF;
    cursor:pointer;
}
.ui-datepicker select.ui-datepicker-month option, .ui-datepicker select.ui-datepicker-year option {
    color: rgba(0,0,0,0.75);
}
.ui-datepicker table {
    border-collapse: collapse;
    font-size: 0.9em;
    margin: 0 0 0.4em;
    width: 100%;
}
.ui-datepicker th {
    border: 0 none;
    font-weight: bold;
    padding: 0.7em 0.3em;
    text-align: center;
}
.ui-datepicker td {
    border: 0 none;
    padding: 1px;
}
.ui-datepicker td span, .ui-datepicker td a {
    display: block;
}
.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    margin: 0.7em 0 0;
    padding: 0 0.2em;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    cursor: pointer;
    float: right;
    margin: 0.5em 0.2em 0.4em;
    overflow: visible;
    padding: 0.2em 0.6em 0.3em;
    width: auto;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}
.ui-datepicker.ui-datepicker-multi {
    width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
    float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0 auto 0.4em;
    width: 95%;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
}
.ui-datepicker-row-break {
    clear: both;
    font-size: 0;
    width: 100%;
}
.ui-datepicker-rtl {
    direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
    left: auto;
    right: 2px;
}
.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
    left: auto;
    right: 1px;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}
.ui-widget {
    font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
    font-size: 1.1em;
}
.ui-widget .ui-widget {
    font-size: 1em;
}
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
    font-size: 1em;
}
.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
    font-weight: bold;
}
.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
    font-weight: normal;
    // opacity: 0.85;
}
.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
    background-image: none;
    // opacity: 0.85;
}
	.ui-state-disabled .ui-icon {
}
.ui-icon {
    background-position: 16px 16px;
    height: 16px;
    width: 16px;
}
.ui-widget-header .ui-icon {
    background-image: url("/img/3/components/ui-icons_ffffff_256x240.png");
}
.ui-icon-carat-1-n {
    background-position: 0 0;
}
.ui-icon-carat-1-ne {
    background-position: -16px 0;
}
.ui-icon-carat-1-e {
    background-position: -32px 0;
}
.ui-icon-carat-1-se {
    background-position: -48px 0;
}
.ui-icon-carat-1-s {
    background-position: -64px 0;
}
.ui-icon-carat-1-sw {
    background-position: -80px 0;
}
.ui-icon-carat-1-w {
    background-position: -96px 0;
}
.ui-icon-carat-1-nw {
    background-position: -112px 0;
}
.ui-icon-carat-2-n-s {
    background-position: -128px 0;
}
.ui-icon-carat-2-e-w {
    background-position: -144px 0;
}
.ui-icon-triangle-1-n {
    background-position: 0 -16px;
}
.ui-icon-triangle-1-ne {
    background-position: -16px -16px;
}
.ui-icon-triangle-1-e {
    background-position: -32px -16px;
}
.ui-icon-triangle-1-se {
    background-position: -48px -16px;
}
.ui-icon-triangle-1-s {
    background-position: -64px -16px;
}
.ui-icon-triangle-1-sw {
    background-position: -80px -16px;
}
.ui-icon-triangle-1-w {
    background-position: -96px -16px;
}
.ui-icon-triangle-1-nw {
    background-position: -112px -16px;
}
.ui-icon-triangle-2-n-s {
    background-position: -128px -16px;
}
.ui-icon-triangle-2-e-w {
    background-position: -144px -16px;
}
.ui-icon-arrow-1-n {
    background-position: 0 -32px;
}
.ui-icon-arrow-1-ne {
    background-position: -16px -32px;
}
.ui-icon-arrow-1-e {
    background-position: -32px -32px;
}
.ui-icon-arrow-1-se {
    background-position: -48px -32px;
}
.ui-icon-arrow-1-s {
    background-position: -64px -32px;
}
.ui-icon-arrow-1-sw {
    background-position: -80px -32px;
}
.ui-icon-arrow-1-w {
    background-position: -96px -32px;
}
.ui-icon-arrow-1-nw {
    background-position: -112px -32px;
}
.ui-icon-arrow-2-n-s {
    background-position: -128px -32px;
}
.ui-icon-arrow-2-ne-sw {
    background-position: -144px -32px;
}
.ui-icon-arrow-2-e-w {
    background-position: -160px -32px;
}
.ui-icon-arrow-2-se-nw {
    background-position: -176px -32px;
}
.ui-icon-arrowstop-1-n {
    background-position: -192px -32px;
}
.ui-icon-arrowstop-1-e {
    background-position: -208px -32px;
}
.ui-icon-arrowstop-1-s {
    background-position: -224px -32px;
}
.ui-icon-arrowstop-1-w {
    background-position: -240px -32px;
}
.ui-icon-arrowthick-1-n {
    background-position: 0 -48px;
}
.ui-icon-arrowthick-1-ne {
    background-position: -16px -48px;
}
.ui-icon-arrowthick-1-e {
    background-position: -32px -48px;
}
.ui-icon-arrowthick-1-se {
    background-position: -48px -48px;
}
.ui-icon-arrowthick-1-s {
    background-position: -64px -48px;
}
.ui-icon-arrowthick-1-sw {
    background-position: -80px -48px;
}
.ui-icon-arrowthick-1-w {
    background-position: -96px -48px;
}
.ui-icon-arrowthick-1-nw {
    background-position: -112px -48px;
}
.ui-icon-arrowthick-2-n-s {
    background-position: -128px -48px;
}
.ui-icon-arrowthick-2-ne-sw {
    background-position: -144px -48px;
}
.ui-icon-arrowthick-2-e-w {
    background-position: -160px -48px;
}
.ui-icon-arrowthick-2-se-nw {
    background-position: -176px -48px;
}
.ui-icon-arrowthickstop-1-n {
    background-position: -192px -48px;
}
.ui-icon-arrowthickstop-1-e {
    background-position: -208px -48px;
}
.ui-icon-arrowthickstop-1-s {
    background-position: -224px -48px;
}
.ui-icon-arrowthickstop-1-w {
    background-position: -240px -48px;
}
.ui-icon-arrowreturnthick-1-w {
    background-position: 0 -64px;
}
.ui-icon-arrowreturnthick-1-n {
    background-position: -16px -64px;
}
.ui-icon-arrowreturnthick-1-e {
    background-position: -32px -64px;
}
.ui-icon-arrowreturnthick-1-s {
    background-position: -48px -64px;
}
.ui-icon-arrowreturn-1-w {
    background-position: -64px -64px;
}
.ui-icon-arrowreturn-1-n {
    background-position: -80px -64px;
}
.ui-icon-arrowreturn-1-e {
    background-position: -96px -64px;
}
.ui-icon-arrowreturn-1-s {
    background-position: -112px -64px;
}
.ui-icon-arrowrefresh-1-w {
    background-position: -128px -64px;
}
.ui-icon-arrowrefresh-1-n {
    background-position: -144px -64px;
}
.ui-icon-arrowrefresh-1-e {
    background-position: -160px -64px;
}
.ui-icon-arrowrefresh-1-s {
    background-position: -176px -64px;
}
.ui-icon-arrow-4 {
    background-position: 0 -80px;
}
.ui-icon-arrow-4-diag {
    background-position: -16px -80px;
}
.ui-icon-extlink {
    background-position: -32px -80px;
}
.ui-icon-newwin {
    background-position: -48px -80px;
}
.ui-icon-refresh {
    background-position: -64px -80px;
}
.ui-icon-shuffle {
    background-position: -80px -80px;
}
.ui-icon-transfer-e-w {
    background-position: -96px -80px;
}
.ui-icon-transferthick-e-w {
    background-position: -112px -80px;
}
.ui-icon-folder-collapsed {
    background-position: 0 -96px;
}
.ui-icon-folder-open {
    background-position: -16px -96px;
}
.ui-icon-document {
    background-position: -32px -96px;
}
.ui-icon-document-b {
    background-position: -48px -96px;
}
.ui-icon-note {
    background-position: -64px -96px;
}
.ui-icon-mail-closed {
    background-position: -80px -96px;
}
.ui-icon-mail-open {
    background-position: -96px -96px;
}
.ui-icon-suitcase {
    background-position: -112px -96px;
}
.ui-icon-comment {
    background-position: -128px -96px;
}
.ui-icon-person {
    background-position: -144px -96px;
}
.ui-icon-print {
    background-position: -160px -96px;
}
.ui-icon-trash {
    background-position: -176px -96px;
}
.ui-icon-locked {
    background-position: -192px -96px;
}
.ui-icon-unlocked {
    background-position: -208px -96px;
}
.ui-icon-bookmark {
    background-position: -224px -96px;
}
.ui-icon-tag {
    background-position: -240px -96px;
}
.ui-icon-home {
    background-position: 0 -112px;
}
.ui-icon-flag {
    background-position: -16px -112px;
}
.ui-icon-calendar {
    background-position: -32px -112px;
}
.ui-icon-cart {
    background-position: -48px -112px;
}
.ui-icon-pencil {
    background-position: -64px -112px;
}
.ui-icon-clock {
    background-position: -80px -112px;
}
.ui-icon-disk {
    background-position: -96px -112px;
}
.ui-icon-calculator {
    background-position: -112px -112px;
}
.ui-icon-zoomin {
    background-position: -128px -112px;
}
.ui-icon-zoomout {
    background-position: -144px -112px;
}
.ui-icon-search {
    background-position: -160px -112px;
}
.ui-icon-wrench {
    background-position: -176px -112px;
}
.ui-icon-gear {
    background-position: -192px -112px;
}
.ui-icon-heart {
    background-position: -208px -112px;
}
.ui-icon-star {
    background-position: -224px -112px;
}
.ui-icon-link {
    background-position: -240px -112px;
}
.ui-icon-cancel {
    background-position: 0 -128px;
}
.ui-icon-plus {
    background-position: -16px -128px;
}
.ui-icon-plusthick {
    background-position: -32px -128px;
}
.ui-icon-minus {
    background-position: -48px -128px;
}
.ui-icon-minusthick {
    background-position: -64px -128px;
}
.ui-icon-close {
    background-position: -80px -128px;
}
.ui-icon-closethick {
    background-position: -96px -128px;
}
.ui-icon-key {
    background-position: -112px -128px;
}
.ui-icon-lightbulb {
    background-position: -128px -128px;
}
.ui-icon-scissors {
    background-position: -144px -128px;
}
.ui-icon-clipboard {
    background-position: -160px -128px;
}
.ui-icon-copy {
    background-position: -176px -128px;
}
.ui-icon-contact {
    background-position: -192px -128px;
}
.ui-icon-image {
    background-position: -208px -128px;
}
.ui-icon-video {
    background-position: -224px -128px;
}
.ui-icon-script {
    background-position: -240px -128px;
}
.ui-icon-alert {
    background-position: 0 -144px;
}
.ui-icon-info {
    background-position: -16px -144px;
}
.ui-icon-notice {
    background-position: -32px -144px;
}
.ui-icon-help {
    background-position: -48px -144px;
}
.ui-icon-check {
    background-position: -64px -144px;
}
.ui-icon-bullet {
    background-position: -80px -144px;
}
.ui-icon-radio-on {
    background-position: -96px -144px;
}
.ui-icon-radio-off {
    background-position: -112px -144px;
}
.ui-icon-pin-w {
    background-position: -128px -144px;
}
.ui-icon-pin-s {
    background-position: -144px -144px;
}
.ui-icon-play {
    background-position: 0 -160px;
}
.ui-icon-pause {
    background-position: -16px -160px;
}
.ui-icon-seek-next {
    background-position: -32px -160px;
}
.ui-icon-seek-prev {
    background-position: -48px -160px;
}
.ui-icon-seek-end {
    background-position: -64px -160px;
}
.ui-icon-seek-start {
    background-position: -80px -160px;
}
.ui-icon-seek-first {
    background-position: -80px -160px;
}
.ui-icon-stop {
    background-position: -96px -160px;
}
.ui-icon-eject {
    background-position: -112px -160px;
}
.ui-icon-volume-off {
    background-position: -128px -160px;
}
.ui-icon-volume-on {
    background-position: -144px -160px;
}
.ui-icon-power {
    background-position: 0 -176px;
}
.ui-icon-signal-diag {
    background-position: -16px -176px;
}
.ui-icon-signal {
    background-position: -32px -176px;
}
.ui-icon-battery-0 {
    background-position: -48px -176px;
}
.ui-icon-battery-1 {
    background-position: -64px -176px;
}
.ui-icon-battery-2 {
    background-position: -80px -176px;
}
.ui-icon-battery-3 {
    background-position: -96px -176px;
}
.ui-icon-circle-plus {
    background-position: 0 -192px;
}
.ui-icon-circle-minus {
    background-position: -16px -192px;
}
.ui-icon-circle-close {
    background-position: -32px -192px;
}
.ui-icon-circle-triangle-e {
    background-position: -48px -192px;
}
.ui-icon-circle-triangle-s {
    background-position: -64px -192px;
}
.ui-icon-circle-triangle-w {
    background-position: -80px -192px;
}
.ui-icon-circle-triangle-n {
    background-position: -96px -192px;
}
.ui-icon-circle-arrow-e {
    background-position: -112px -192px;
}
.ui-icon-circle-arrow-s {
    background-position: -128px -192px;
}
.ui-icon-circle-arrow-w {
    background-position: -144px -192px;
}
.ui-icon-circle-arrow-n {
    background-position: -160px -192px;
}
.ui-icon-circle-zoomin {
    background-position: -176px -192px;
}
.ui-icon-circle-zoomout {
    background-position: -192px -192px;
}
.ui-icon-circle-check {
    background-position: -208px -192px;
}
.ui-icon-circlesmall-plus {
    background-position: 0 -208px;
}
.ui-icon-circlesmall-minus {
    background-position: -16px -208px;
}
.ui-icon-circlesmall-close {
    background-position: -32px -208px;
}
.ui-icon-squaresmall-plus {
    background-position: -48px -208px;
}
.ui-icon-squaresmall-minus {
    background-position: -64px -208px;
}
.ui-icon-squaresmall-close {
    background-position: -80px -208px;
}
.ui-icon-grip-dotted-vertical {
    background-position: 0 -224px;
}
.ui-icon-grip-dotted-horizontal {
    background-position: -16px -224px;
}
.ui-icon-grip-solid-vertical {
    background-position: -32px -224px;
}
.ui-icon-grip-solid-horizontal {
    background-position: -48px -224px;
}
.ui-icon-gripsmall-diagonal-se {
    background-position: -64px -224px;
}
.ui-icon-grip-diagonal-se {
    background-position: -80px -224px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 4px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: 4px;
}
.ui-widget-overlay {
    opacity: 0.5;
}
.ui-widget-shadow {
    border-radius: 5px;
    margin: -5px 0 0 -5px;
    opacity: 0.2;
    padding: 5px;
}


/* Custom styling */
table.ui-datepicker-calendar {border:none;}

.ui-widget {
	background: $primary-color;
	border: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ui-datepicker {
	padding: 0;
}

.ui-datepicker-header {
	border: none;
	background: $secondary-color;
	font-weight: normal;
	font-size: $main-font-size;
}
#ui-datepicker-div table.ui-datepicker-calendar thead {background: $secondary-color !important;}

.ui-datepicker-header .ui-state-hover {
	background: transparent;
	border-color: transparent;
	cursor: pointer;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ui-datepicker .ui-datepicker-title {
	margin-top: .4em;
	margin-bottom: .4em;
	color: darken($primary-color, 10%);
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
	top: 1.1em;
	border:none;
}

.ui-datepicker .ui-datepicker-prev-hover {
	left: 2px;
}

.ui-datepicker .ui-datepicker-next-hover {
	right: 2px;
}

.ui-datepicker .ui-datepicker-next span,
.ui-datepicker .ui-datepicker-prev span {
	background-image: url(/img/3/components/ui-icons_ffffff_256x240.png);
	background-position: -32px 0;
	margin-top: 0;
	top: 0;
	font-weight: normal;
}

.ui-datepicker .ui-datepicker-prev span {
	background-position: -96px 0;
}

.ui-datepicker table {
	margin: 0;
}

.ui-datepicker th {
	padding: 1em 0;
	color: #FFF;
	font-size: 13px;
	font-weight: normal;
	border: none;
	border-top: 1px solid lighten($secondary-color, 2%);
}

.ui-datepicker td {
	background: $primary-color;
	border: none;
	padding: 0;
}

td .ui-state-default {
	background: transparent;
	border: none;
	text-align: center;
	padding: .5em;
	margin: 0;
	font-weight: normal;
	color: #FFF;
	font-size: $main-font-size;
}

.ui-state-disabled  {
	opacity: 1;
}

.ui-state-disabled .ui-state-default {
	color: #fba49e;
}

td .ui-state-active,
td .ui-state-hover {
	background: darken($primary-color, 10%);
}


@media only screen and (max-width: 40em) {
    .ui-datepicker {width: 14em;}
}