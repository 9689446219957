@charset "UTF-8";

.poll.variant1 {
  .pollmax10textoptions_title_label_container{display:none;}
  table{border:0;}
  .pollmax10textoptions_answer_radiobuttons_container input[type="radio"] {margin-bottom:0;}
  .pollmax10textoptions_result_button_container input,
  .pollmax10textoptions_vote_button_container input {@include button;}
  .pollmax10textoptions_vote_button_container {clear:none;}
  .pollmax10textoptions_result_button_container {margin-right:rem-calc(10); float:left;}
  .pollmax10textoptions_result_bar {-webkit-animation: slideInRight 2s;-moz-animation: slideInRight 2s;-o-animation: slideInRight 2s;animation: slideInRight 2s;height:23px;content:" ";background:$primary-color;margin:0 rem-calc(10) rem-calc(10) 0;}
  .pollmax10textoptions_results_container div:nth-child(3n+1) {clear:left;margin-bottom:rem-calc(5);padding-top:$padding-small;border-top:1px solid $border-color;}
  .pollmax10textoptions_results_container + #button_container_pollvote_poll_Block_Poll {clear:left;}
  @if $block-poll-table-or-div == 'div' {
    table, tr, td {display:block;}
    tr {background:none;}
    td {padding:rem-calc(5px) rem-calc(10px);}
  }
  table tr td {border-bottom: $block-poll-underline;}

  @if $block-poll-vote-button-position == 'right' {
    .pollmax10textoptions_vote_button_container input {float:right;}
    .pollmax10textoptions_results_container + #button_container_pollvote_poll_Block_Poll {float:left;}
  } @else {
    .pollmax10textoptions_vote_button_container input {margin-left:rem-calc(10px); float:left;}
  }
}

@keyframes slideInRight {
  0% {
    width:0;
  }
}
@-webkit-keyframes slideInRight {
  0% {
    width:0;
  }
}
@-o-keyframes slideInRight {
  0% {
    width:0;
  }
}
@-moz-keyframes slideInRight {
  0% {
    width:0;
  }
}