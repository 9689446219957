@charset "UTF-8";

.persons.variant1 {
	.person {
		.personContent {position:relative;
			h4, h4 a{color:#fff;font-size:rem-calc(14);margin:0; display:$block-persons-show-title;}
		}
		.overlay {color:#fff;background:rgba(0,0,0,0.4);position:absolute;left:0;bottom:0;width:100%;box-sizing:border-box;height:100%;padding:$padding-normal;overflow:hidden;display:$block-persons-show-overlay;
			@if $block-persons-link-to-detail != 'none' {
				pointer-events:none;
			}
			.slideHidden{font-size:rem-calc(12);
				p{font-size:rem-calc(12);}
				a{font-size:rem-calc(12);color:#fff;text-decoration:underline;}
			}
		}
		&:hover .overlay{transition:0.5s;height:100%;background:rgba(0,0,0,0.6);}
		.personImg{overflow:hidden;
			a {display:block;}
		}
		.personImg img{
			filter:blur(0px);
			-webkit-filter: blur(0px);
			-moz-filter: blur(0px);
			-o-filter: blur(0px);
			-ms-filter: blur(0px);
			//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				filter: url(#blur);
				filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
			//}
			width:100%;
		}
		&:hover .personImg img {
			@if $block-persons-show-overlay != "none" {
				filter:blur(8px);
				-webkit-filter: blur(8px);
				-moz-filter: blur(8px);
				-o-filter: blur(8px);
				-ms-filter: blur(8px);
				//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					filter: url(#blur2);
					filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
				//}
				transition:0.5s;
			}
		}
		&:hover .slideHidden{display:block;}
		.slideHidden{display:none;margin:rem-calc(10) 0 0 0;}
		// h4.bottom a,
		// h4.bottom{text-align:center;font-size:rem-calc(14);}
		// .overlay .slideHidden{height:0;}
		//&:hover .overlay .slideHidden{height:auto;transition:1s;}
		.overlay h4 a {pointer-events:$block-persons-link-to-detail;}
	}
}

.large-4 .persons.variant1 .person:hover .slideHidden {display:none;}
.large-4 .persons.variant1 .person .personContent h4, 
.large-4 .persons.variant1 .person .personContent h4 a {font-size:rem-calc(12);}

@keyframes fadeInUp{
	0%{
		height:0;
	}
	100%{
		height:100%;
	}
}
@-webkit-keyframes fadeInUp{
	0%{
		height:0;
	}
	100%{
		height:100%;
	}
}