@charset "UTF-8";

.pages.variant3 {
	.grid-item {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin-bottom:10px;
		&:hover {cursor:pointer; .text {background:$primary-color; color:$white; a{color:$white;}}}
		article {margin:10px; border:1px solid #dddddd;
			.afbeelding {overflow:hidden; width:100%; height:160px; position:relative; margin-bottom:0 !important; border-bottom:10px solid $primary-color;
				.afbeelding-inner {width:1000px !important; position:absolute; height:100%; max-width:100%; -webkit-background-size: cover !important; -moz-background-size: cover !important; -o-background-size: cover !important; background-size: cover !important; background-position: center !important;}
			}
			.text {padding:10px; -webkit-transition: background-color 0.2s ease-in-out; -moz-transition: background-color 0.2s ease-in-out; -o-transition: background-color 0.2s ease-in-out; transition: background-color 0.2s ease-in-out;
				time {font-size:rem-calc(15px);color:$date-color;}
			}
		}
	}
}