@charset "UTF-8";

.hideOnForm {
  display: none;
}

.meetingsSubscriptionFlow.variant1 {
  @include meetingSubscriptionFlow;
  float:left;
  width:100%;
  clear:left;
  margin-top:20px;
  .loading{
    .button-group{
      &::before{
        content:"";
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
        width:18px;
        height:18px;
        float:left;
        display:block;
        border-left:2px solid #ccc;
        border-top:2px solid #ccc;
        border-radius:50%;
        margin:17px 20px;
      }
    }
  }
  button {
    @include button;
    margin:0 10px 10px 0;
  }
  .button.light {
    @include buttonLight;
  }
  .button-group.large {
    .button {
      margin: 0 10px 10px 0;
    }
  }
  .addColleagueErrorMsg{
    display:none;
    &.visible{
      display:block!important;
    }
  }
  ul.personsGrid {
    padding-left: 0;
    margin-left: 0;
    li {
      overflow: hidden;
      padding: 5px 0;
      list-style-type: none;
      span.personLabel {
        float: left;
        span.personRelation {
          display: block;
          font-size: rem-calc(13);
        }
      }
      .button-group {
        float: right;
        button {
          @include buttonSmall;
          margin: 0 10px 10px 0;
        }
      }
    }
  }
  // Form
  h4.formTitle{
    margin-bottom:20px;
  }
  .fb-form-object {
    margin-bottom: 15px;
    .alert-warning{color:red;}
    label.fb-required::after{
      content:'*';
      color: $warning-color;
    }
    .componentSeparator {
      margin-bottom:40px;
    }
    .componentSeparator,
    .componentTextarea,
    .componentText {
      label.text-right {
        margin-top: 8px;
      }
    }
    .componentCaptcha {
      label.text-right {
        margin-top: 25px;
      }
    }
    .componentProvision,
    .componentProgram{
      .sessionFull{
        .sessionName{
          text-decoration: line-through;
        }
      }
      .checkbox{
        //margin-bottom:5px;
		white-space:nowrap;
        float:left;
        width:100%;
        input[type=checkbox]{
          float:left;
          margin-top:4px;
        }
		input[type="checkbox"]+label {
			white-space:normal!important;
		}
        label{
          &.fb-required{
            &::after{display:none;}
            .provisionName,
            .sessionName{
              &::after{
                content:"*";
                color:#f04124;
                margin-left:5px;
              }
            }
          }
          .labelInfo{
            display:block;
            font-style:italic;
          }
          .labelInfoSmall{
            display:block;
            color: #bbb;
          }
        }
      }
    }
    .componentProgram{
      .sessionOverlapMsg::after{
        content: " (overlap)";
      }
    }

    p.help-text {
      color: #bbb;
      font-size: 14px;
    }
    p.help-text:empty {
      display: none;
    }
  }
  .searchAndSelect{
    position:relative;
    .findColleague-popup-wrapper{
      position:absolute;
      left:0;
      top:10px;
      z-index:10;
      background:#fff;
      width:100%;
      border-left:1px solid #ccc;
      border-right:1px solid #ccc;
      border-bottom:1px solid #ccc;
      padding:10px 15px;
      ul{

      }
    }
  }
}

@media #{$medium-down} {
  .meetingsSubscriptionFlow.variant1 {
    ul.personsGrid {
      li {
        .button-group {
          float: left;
          clear: left;
          button {
            margin: 0 10px 10px 0;
          }
        }
      }
    }
  }
}

// Select variables
$select-bg-color: #f8f8f8 !default;
$select-hover-bg-color: scale-color($select-bg-color, $lightness: -3%) !default;

// We use this mixin to style select elements
@mixin form-select  {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: $select-bg-color;
  border-radius: 0;

  // Hide the dropdown arrow shown in newer IE versions
  &::-ms-expand {
    display: none;
  }

  // The custom arrow has some fake horizontal padding so we can align it
  // from the right side of the element without relying on CSS3
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+');

  // We can safely use leftmost and rightmost now
  background-position: left center;

  background-repeat: no-repeat;
  border: 1px solid #CCC;
  color: $main-color;
  font-family: $main-font;
  font-size: $main-font-size;
  line-height: normal;
  &:focus {
    background-color: $select-hover-bg-color;
  }
  // Disabled Styles
  &:disabled {
    background-color: #CCC;
    cursor: default;
  }
}

// Select2
.ui-select-container{
  position:relative;
  margin-bottom:1rem;
  .select2-choice{
    //color:rgba(0,0,0,0.75);
    display:block;
    color:#4d4d4d;
    width:100%;
    background-color:#fafafa;
    border:1px solid #cccccc;
    font-size:.875rem;
    padding:8px 9px 6px 9px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
    background-position: 100% center;
    background-repeat: no-repeat;
    //@include form-select;
    &:hover{
      border:1px solid #999999;
      background-color:#f3f3f3;
    }
  }
  .ui-select-dropdown{
    background:#fff;
    width:100%;
    position:absolute;
    left:0;
    z-index:100;
  }
  .ui-select-focusser{display:none;}
  ul.ui-select-choices{
    margin:0;
    list-style-type:none;
    border-left: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
    li.ui-select-choices-group{
      ul{
        margin:0;
        list-style-type:none;
        li.ui-select-choices-row{
          cursor:default;
          font-size:14px;
          padding:7px 9px;
          &.select2-highlighted{
            background:#f9f9f9;
          }
          &:hover{
            background:$primary-color;
            color:#fff;
          }
          small{
            display:block;
            font-size:13px;
          }
        }
      }
    }
  }
  .select2-display-none{
    ul.ui-select-choices{
      border:0;
    }
  }
}
