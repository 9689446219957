@charset "UTF-8";

.pages.variant6 {
	.active {font-weight:bold;}
	#years {
		span, a {margin: 0 10px;}
		:first-child {margin-left:0;}
	}
	#months {
		span, a {margin: 0 10px;}
		:first-child {margin-left:0;}
	}
	#period {margin-top: 15px;}
	.item span.date {display:inline-block;padding-right:20px;}
}