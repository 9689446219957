@charset "UTF-8";

.pages.variant5 {
	.grid-item .text {padding:0 15px;
		ul{ margin-left:0;
			overflow:hidden;
		  > li{ 
		    list-style:none; 
		    position:relative; 
		    padding:0 0 0 1.3em;
		    margin:0 0 .5em 10px;
		    transition:.12s;
		    
		    &::before{
		      position:absolute;
		      content:'\2022'; 
		      font-family:Arial;
		      color:$primary-color;
		      top:0;
		      left:0;
		      text-align:center; 
		      font-size:2em; 
		      opacity:.5;
		      line-height:.75; 
		      transition:.5s;
		    }
		    
		    &:hover{
		      color:$black;
		      &::before{
		        transform:scale(1.5); 
		        opacity:1; 
		        text-shadow:0 0 4px;
		        transition:.1s;
		      }
		    }
		  }
		}
	}
}