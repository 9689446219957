@charset "UTF-8";

.pages.variant1 {
	article{padding:$padding-normal; border:1px solid $border-color; margin:0 0 $margin-normal 0!important;
		.column img {padding-right: 15px;}
	}
	time {color:$date-color;}
	.column.img {padding:0 rem-calc(10) 0 0;}

}

.large-1, .large-2, .large-3, .large-4, .large-5, .large-6, .large-7 {
	.pages.variant1 {
		article {border-top:0;border-left:0;border-right:0;padding-right:0;padding-left:0;}
		.img,
		.content p,
		span.btn {display:none;}
		.content {width:100%;}
		time {float:left;font-size:$main-font-size;margin-top:$margin-small;}
		h4 {float:right;width:60%;}
	}
}

@media #{$small-up}{
	.pages.variant1 article .column.img {text-align:center;
		img {padding-right:0;}
	}
}
@media #{$large-up}{
	.pages.variant1 article .column.img img {padding-right:15px;}
}


/*@media #{$large-only}{
	.large-5 .pagesVariant1 .img,
	.large-4 .pagesVariant1 .img {display:none;} 
	.large-5 .pagesVariant1 .content,
	.large-4 .pagesVariant1 .content {width:100%;}
}*/