@charset "UTF-8";

.documentDetail.variant1{
  .docLabel{
    min-width:150px;
    float:left;
  }
  .docValue {
    .label {display:none;}
  }
  .relatedDocuments{
    margin:30px 0 0 0;
    .docTitle{
      font-weight:bold;
    }
  }
}