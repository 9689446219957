@charset "UTF-8";

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@mixin rangeTrack {
	width: 100%;
	height: 5px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 0px 0px 0px #000000;
	background: $primary-color;
	border-radius: 1px;
	border: 0px solid #000000;
}
@mixin rangeTrackFiller {
	background: $primary-color;
}
@mixin rangeButton {
	box-shadow: 0px 0px 0px #000000;
    border: 1px solid $primary-color;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: lighten($primary-color, 10%);
    cursor: pointer;
}
@mixin rangeFillerIE {
	background: $primary-color;
	border: 0px solid #000000;
	border-radius: 2px;
	box-shadow: 0px 0px 0px #000000;
}

.formV3.variant1{
  .loading{
    .button-group .button{
	  padding-left:1.5rem;
      &::before{
        content:"";
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
        width:18px;
        height:18px;
        float:left;
        display:block;
        border-left:2px solid #ccc;
        border-top:2px solid #ccc;
        border-radius:50%;
        margin-right:10px;
      }
    }
  }
  .fb-form-object {
    margin-bottom: 15px;
    .alert-warning{color:red;}
    label.fb-required::after {
      content:'*';
      color: $warning-color;
    }
    .componentSeparator {
      margin-bottom:40px;
    }
    .componentSeparator,
    .componentTextarea,
    .componentText {
      label.text-right {
        margin-top: 8px;
      }
    }
    .componentCaptcha {
      label.text-right {
        margin-top: 25px;
		&::after {
		  content:'*';
		  color: $warning-color;
		}
      }
    }
    p.help-text {
      color: #bbb;
      font-size: 14px;
    }
    p.help-text:empty {
      display: none;
    }
  }
  // Range component
  input[type=range] {
    -webkit-appearance: none;
    margin:0;
	float:left;
    width:80%;
	padding:0;
	min-height:25px;
	
  }
  .componentRange {
    .minValue {
      padding-right:10px;
    }
    .maxValue {
      padding-left:10px;
	  text-align:right;
    }
    .minValue,
    .maxValue {
      float:left;
	  width:10%;
    }
  }
  input[type=range]:focus {
    outline: none;
  }
  
  /* Range Track */
  input[type=range]::-webkit-slider-runnable-track {@include rangeTrack;}
  input[type=range]::-moz-range-track {@include rangeTrack;}
  input[type=range]::-ms-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      color: transparent;
  }
  
  /* Range Filler */
  input[type=range]:focus::-webkit-slider-runnable-track {@include rangeTrackFiller;}
  input[type=range]:focus::-ms-fill-lower {@include rangeTrackFiller;}
  input[type=range]:focus::-ms-fill-upper {@include rangeTrackFiller;}

  /* Range button */
  input[type=range]::-moz-range-thumb {@include rangeButton;}
  input[type=range]::-ms-thumb {@include rangeButton;}
  input[type=range]::-webkit-slider-thumb {@include rangeButton;-webkit-appearance: none;margin-top: -7px;}
  
  /* Range Filler IE */
  input[type=range]::-ms-fill-lower {@include rangeFillerIE;}
  input[type=range]::-ms-fill-upper {@include rangeFillerIE;}
}